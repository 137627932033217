import React from "react";

const Footer=()=>{
    return (
        <footer className="footer">
            <div className="container-fluid d-flex justify-content-between">
            <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">
              Power By : XYZ Company
            </span>

            </div>
        </footer>
    )
};

export  default  Footer;