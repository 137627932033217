import React, { useReducer, useState } from "react";
import Footer from "./Footer";
import Hdr from "../Hdr";
import SelectNavBar from "../brds/navbar/SelectNavBar";
import CpcbDshbrdClr from "../../components/reusable/advncd/CpcbDshbrdClr";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffectOnce } from "react-use";
import Chart from "react-google-charts";
import { SIZE } from "rsuite/esm/utils/constants";

const ACTIONS = {
  SETSUMMARY: "grdtrigger",
};

const initialState = {
  triggerG: 0,
  nwRow: [],
  rndm: 0,
  trigger: 0,
  textDts: "",
  gnrtd: 0,
  ylwgnrtdcnt: 0,
  ylwgnrtdwt: 0,
  rdgnrtdcnt: 0,
  rdgnrtdwt: 0,
  blugnrtdcnt: 0,
  blugnrtdwt: 0,
  whtgnrtdcnt: 0,
  whtgnrtdwt: 0,
  cytgnrtdcnt: 0,
  cytgnrtdwt: 0,
  coltd:0,
  ylwcltdcnt: 0,
  ylwcltddwt: 0,
  rdcltddcnt: 0,
  rdcltddwt: 0,
  blucltddcnt: 0,
  blucltddwt: 0,
  whtcltddcnt: 0,
  whtcltddwt: 0,
  cytcltddcnt: 0,
  cytcltddwt: 0,
  prcsd:0,
  ylwprcdcnt: 0,
  ylwprcddwt: 0,
  rdprcddcnt: 0,
  rdprcddwt: 0,
  bluprcddcnt: 0,
  bluprcddwt: 0,
  whtprcddcnt: 0,
  whtprcddwt: 0,
  cytprcddcnt: 0,
  cytprcddwt: 0,
  hcfvst:0,
  errapi:0,
};

type purBill = {
  triggerG: number;
  nwRow: any;
  rndm: number;
  trigger: number;
  textDts: string;
  gnrtd : number;
  ylwgnrtdcnt: number;
  ylwgnrtdwt: number;
  rdgnrtdcnt: number;
  rdgnrtdwt: number;
  blugnrtdcnt: number;
  blugnrtdwt: number;
  whtgnrtdcnt: number;
  whtgnrtdwt: number;
  cytgnrtdcnt: number;
  cytgnrtdwt: number;
  coltd: number;
  ylwcltdcnt: number;
  ylwcltddwt: number;
  rdcltddcnt: number;
  rdcltddwt: number;
  blucltddcnt: number;
  blucltddwt: number;
  whtcltddcnt: number;
  whtcltddwt: number;
  cytcltddcnt: number;
  cytcltddwt: number;
  prcsd:number,
  ylwprcdcnt: number;
  ylwprcddwt: number;
  rdprcddcnt: number;
  rdprcddwt: number;
  bluprcddcnt: number;
  bluprcddwt: number;
  whtprcddcnt: number;
  whtprcddwt: number;
  cytprcddcnt: number;
  cytprcddwt: number;
  hcfvst:number,
  errapi:number,
};

type act = {
  type: string;
  payload: any;
};

const reducer = (state: purBill, action: act) => {
  let newstate: any = { ...state };
  switch (action.type) {
    case ACTIONS.SETSUMMARY:
      newstate.ylwgnrtdcnt= Number(action.payload['ylw_gnrtdcnt'])
      newstate.ylwgnrtdwt= Number(action.payload['ylw_gnrtdwt'])
      newstate.rdgnrtdcnt=action.payload['red_gnrtdcnt']
      newstate.rdgnrtdwt=action.payload['red_gnrtdwt']
      newstate.blugnrtdcnt=action.payload['blu_gnrtdcnt']
      newstate.blugnrtdwt=action.payload['blu_gnrtdwt']
      newstate.whtgnrtdcnt=action.payload['wht_gnrtdcnt']
      newstate.whtgnrtdwt=action.payload['wht_gnrtdwt']
      newstate.cytgnrtdcnt=action.payload['cyt_gnrtdcnt']
      newstate.cytgnrtdwt=action.payload['cyt_gnrtdwt']
      newstate.gnrtd =(Number(action.payload['ylw_gnrtdwt'])+ Number(action.payload['red_gnrtdwt']) + Number(action.payload['blu_gnrtdwt']) + Number(action.payload['wht_gnrtdwt']) + Number(action.payload['cyt_gnrtdwt'])).toFixed(3)
     
      newstate.ylwcltdcnt= Number(action.payload['ylw_cltdcnt'])
      newstate.ylwcltdwt= Number(action.payload['ylw_cltdwt'])
      newstate.rdcltdcnt=action.payload['red_cltdcnt']
      newstate.rdcltdwt=action.payload['red_cltdwt']
      newstate.blucltdcnt=action.payload['blu_cltdcnt']
      newstate.blucltdwt=action.payload['blu_cltdwt']
      newstate.whtcltdcnt=action.payload['wht_cltdcnt']
      newstate.whtcltdwt=action.payload['wht_cltdwt']
      newstate.cytcltdcnt=action.payload['cyt_cltdcnt']
      newstate.cytcltdwt=action.payload['cyt_cltdwt']
      newstate.coltd =(Number(action.payload['ylw_cltdwt'])+ Number(action.payload['red_cltdwt']) + Number(action.payload['blu_cltdwt']) + Number(action.payload['wht_cltdwt']) + Number(action.payload['cyt_cltdwt'])).toFixed(3)
     

      newstate.ylwcltdcnt= Number(action.payload['ylw_prsdcnt'])
      newstate.ylwprcdwt= Number(action.payload['ylw_prsdwt'])
      newstate.rdprcdcnt=action.payload['red_prsdcnt']
      newstate.rdprcdwt=action.payload['red_prsdwt']
      newstate.bluprcdcnt=action.payload['blu_prsdcnt']
      newstate.bluprcdwt=action.payload['blu_prsdwt']
      newstate.whtprcdcnt=action.payload['wht_prsdcnt']
      newstate.whtprcdwt=action.payload['wht_prsdwt']
      newstate.cytprcdcnt=action.payload['cyt_prsdcnt']
      newstate.cytprcdwt=action.payload['cyt_prsdwt']
      newstate.prcsd =(Number(action.payload['ylw_prsdwt'])+ Number(action.payload['red_prsdwt']) + Number(action.payload['blu_prsdwt']) + Number(action.payload['wht_prsdwt']) + Number(action.payload['cyt_prsdwt'])).toFixed(3)
     
      newstate.hcfvst=Number(action.payload['hcfvisited']).toFixed(0)
      newstate.errapi=Number(action.payload['wrngapi']).toFixed(0)
      // newstate.ylwcltdcnt: 0,
      // newstate.ylwcltddwt: 0,
      // newstate.rdcltddcnt: 0,
      // newstate.rdcltddwt: 0,
      // newstate.blucltddcnt: 0,
      // newstate.blucltddwt: 0,
      // newstate.whtcltddcnt: 0,
      // newstate.whtcltddwt: 0,
      // newstate.cytcltddcnt: 0,
      // newstate.cytcltddwt: 0,
      // newstate.ylwprcdcnt: 0,
      // newstate.ylwprcddwt: 0,
      // newstate.rdprcddcnt: 0,
      // newstate.rdprcddwt: 0,
      // newstate.bluprcddcnt: 0,
      // newstate.bluprcddwt: 0,
      // newstate.whtprcddcnt: 0,
      // newstate.whtprcddwt: 0,
      // newstate.cytprcddcnt: 0,
      // newstate.cytprcddwt: 0,
      return newstate;
  }
};

const Dashboard = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  let [time, reTime] = useState("");
  let [date, reDate] = useState("");

  setTimeout(function () {
    let dt = new Date();
    reTime(dt.toLocaleTimeString());
    reDate(`${dt.getDate()}-${dt.getMonth() + 1}-${dt.getFullYear()}`);
  }, 1000);

  const GetData = () => {
    const data = {
      dshbfr: "cpcb"
    };
    axios
    .post("https://api.cliniciankhoj.com/dshbrd",  data)
    //.post("https://api.cliniciankhoj.com/showcpcb", data)
      .then((response) => {
        // console.log(response);
        // console.log(response.data['ylw_gnrtdcnt'])
        dispatch({type : ACTIONS.SETSUMMARY, payload:response.data})
        setTimeout(function(){
          updWst()
        },900)
      }).catch((err)=>{
        console.log(err)
      });
      setTimeout(function(){
        GetData()
      },60000)
  };

  const ShowData = (data: any) => {
    console.log(data);
  };

  const { data, refetch } = useQuery({
    queryKey: ["dshbrd", state.rndm],
    queryFn: GetData,
    enabled: false,
    // staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: ShowData,
  });

  const options = {
    colors: ['#ff0000','blue','green'],
     chart: {
     
      title: "Biomedical Waste Collection" ,
      subtitle: "Color and Category Wise",
      // is3D:true,
      
      
     },
    //  className : "fntCnzl",
     vAxis : { 
      textStyle : {
          fontSize: '27px' // or the number you want
      },

      style : {fontsize : '22px'}
  }
  };

  // const [datachrt, setDataChrt] = useState([])
  const datachrt = [
    ["Color", "Generated", "Collected", "Processed"],
    ["Yellow", state.ylwgnrtdwt, state.ylwcltdwt, state.ylwprcdwt],
    ["Red",  state.rdgnrtdwt, state.rdcltdwt, state.rdprcdwt],
    ["Blue",  state.cytgnrtdwt, state.cytcltdwt, state.cytprcdwt],
    ["White",   state.whtgnrtdwt, state.whtcltdwt, state.whtprcdwt],
    ["Cytotoxic",   state.cytgnrtdwt, state.cytcltdwt, state.cytprcdwt],
  ];
  useEffectOnce(() => {
    // refetch()
    GetData();
  });

  const updWst= ()=>{
    // let ary : any;
    // ary = ['Yellow',state.ylw_gnrtdwt,state.ylw_cltdwt,state.ylw_prsdwt]
    // if (datachrt){
    //   setDataChrt(ary)
    // } else {
    //   setDataChrt(ary)
    // }
    
  }
  return (
    <>
      <div className="container-scroller">
        {/* <Header /> */}
        <Hdr></Hdr>
        {/* <SelectNavBar lvl=""></SelectNavBar> */}
        <div className="container-fluid page-body-wrapper">
          {/* <Sidebar /> */}
          <div className="main-panel" style={{ width: "100%" }}>
            <div className="content-wrapper p-4">
              {/* <div className="page-header">
                <h3 className="page-title">
                  <span className="page-title-icon bg-gradient-primary text-white me-2">
                  
                  </span>
                  Dashboard
                </h3>
                <nav aria-label="breadcrumb">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">
                  
                    </li>
                  </ul>
                </nav>
              </div> */}
              {/* <div className="card mb-3">
                <div className="card-header text-center pt-3">
                  <div className="row">
                    <div className="col-md-6">
                      <h4 className="text-primary d-flex align-items-center">
                        {" "}
                        <i className="mdi mdi-chip icon-md" /> CPCP
                      </h4>
                    </div>
                    <div className="col-md-3">
                      <h4 className="text-success d-flex align-items-center">
                        <i className="mdi mdi-calendar icon-md" /> {date}{" "}
                      </h4>
                    </div>
                    <div className="col-md-3">
                      <h4 className="text-info d-flex align-items-center">
                        <i className="mdi mdi-clock icon-md" /> {time}
                      </h4>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="row">
                <div className="col-md-1 d-none d-sm-block  stretch-card grid-margin"></div>
                <div className="col-md-3 col-sm-1 stretch-card grid-margin dshbrdMain" style={{marginLeft:'-50px'}}>
                  <div className="card bg-gradient-dark card-img-holder text-black">
                    <div className="card-body">
                      {/* <img src={circle} className="card-img-absolute" alt="" /> */}
                      <h3 className="font-weight-bold mb-3 text-center">
                        Generated
                      </h3>
                      <h4 className="mb-4 text-center">{state.gnrtd} Kg</h4>
                      <div className="mt-2">
                        <CpcbDshbrdClr
                          ClassNme="dshylw dshbrditm"
                          TotalCount={state.ylwgnrtdcnt + ' No'}
                          TotalWt={state.ylwgnrtdwt + ' Kg'}
                        ></CpcbDshbrdClr>
                      </div>
                      <div className="mt-2">
                        <CpcbDshbrdClr
                          ClassNme="dshred dshbrditm"
                          TotalCount={state.rdgnrtdcnt + ' No'}
                          TotalWt={state.rdgnrtdwt + ' Kg'}
                        ></CpcbDshbrdClr>
                      </div>
                      <div className="mt-2">
                        <CpcbDshbrdClr
                          ClassNme="dshblu dshbrditm"
                          TotalCount={state.blugnrtdcnt + ' No'}
                          TotalWt={state.blugnrtdwt + ' Kg'}
                        ></CpcbDshbrdClr>
                      </div>
                      <div className="mt-2">
                        <CpcbDshbrdClr
                          ClassNme="dshbrditm"
                          TotalCount={state.whtgnrtdcnt + ' No'}
                          TotalWt={state.whtgnrtdwt + ' Kg'}
                        ></CpcbDshbrdClr>
                      </div>
                      <div className="mt-2">
                        <CpcbDshbrdClr
                          ClassNme="dshbrditm dshcyt"
                          TotalCount={state.cytgnrtdcnt + ' No'}
                          TotalWt={state.cytgnrtdwt + ' Kg'}
                        ></CpcbDshbrdClr>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-1 d-none d-sm-block  stretch-card grid-margin"></div>
                <div className="col-md-3 col-sm-1 stretch-card grid-margin dshbrdMainGreen">
                  <div className="card bg-gradient-dark card-img-holder text-black">
                    <div className="card-body">
                      {/* <img src={circle} className="card-img-absolute" alt="" /> */}
                      <h3 className="font-weight-bold mb-3  text-center">Collected</h3>
                      <h4 className="mb-4  text-center">{state.coltd} Kg</h4>
                      <div className="mt-2">
                        <CpcbDshbrdClr
                          ClassNme="dshylw dshbrditm"
                          TotalCount={state.ylwcltdcnt + ' No'}
                          TotalWt={state.ylwcltdwt + ' Kg'}
                        ></CpcbDshbrdClr>
                      </div>
                      <div className="mt-2">
                        <CpcbDshbrdClr
                          ClassNme="dshred dshbrditm"
                          TotalCount={state.rdcltdcnt + ' No'}
                          TotalWt={state.rdcltdwt + ' Kg'}
                        ></CpcbDshbrdClr>
                      </div>
                      <div className="mt-2">
                        <CpcbDshbrdClr
                          ClassNme="dshblu dshbrditm"
                          TotalCount={state.blucltdcnt + ' No'}
                          TotalWt={state.blucltdwt + ' Kg'}
                        ></CpcbDshbrdClr>
                      </div>
                      <div className="mt-2">
                        <CpcbDshbrdClr
                          ClassNme="dshbrditm"
                          TotalCount={state.whtcltdcnt + ' No'}
                          TotalWt={state.whtcltdwt + ' Kg'}
                        ></CpcbDshbrdClr>
                      </div>
                      <div className="mt-2">
                        <CpcbDshbrdClr
                          ClassNme="dshbrditm dshcyt"
                          TotalCount={state.cytcltdcnt + ' No'}
                          TotalWt={state.cytcltdwt + ' Kg'}
                        ></CpcbDshbrdClr>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-1 d-none d-sm-block  stretch-card grid-margin"></div>
                <div className="col-md-3 col-sm-1 stretch-card grid-margin dshbrdMainBlue">
                  <div className="card bg-gradient-dark card-img-holder text-black">
                    <div className="card-body">
                      {/* <img src={circle} className="card-img-absolute" alt="" /> */}
                      <h3 className="font-weight-bold mb-3  text-center">Processed</h3>
                      <h4 className="mb-4  text-center">{state.prcsd} Kg</h4>
                      <div className="mt-2">
                        <CpcbDshbrdClr
                          ClassNme="dshylw dshbrditm"
                          TotalCount={state.ylwcltdcnt + ' No'}
                          TotalWt={state.ylwprcdwt + ' Kg'}
                        ></CpcbDshbrdClr>
                      </div>
                      <div className="mt-2">
                        <CpcbDshbrdClr
                          ClassNme="dshred dshbrditm"
                          TotalCount={state.rdprcdcnt + ' No'}
                          TotalWt={state.rdprcdwt + ' Kg'}
                        ></CpcbDshbrdClr>
                      </div>
                      <div className="mt-2">
                        <CpcbDshbrdClr
                          ClassNme="dshblu dshbrditm"
                          TotalCount={state.bluprcdcnt + ' No'}
                          TotalWt={state.bluprcdwt + ' Kg'}
                        ></CpcbDshbrdClr>
                      </div>
                      <div className="mt-2">
                        <CpcbDshbrdClr
                          ClassNme="dshbrditm"
                          TotalCount={state.whtprcdcnt + 'No'}
                          TotalWt={state.whtprcdwt + ' Kg'}
                        ></CpcbDshbrdClr>
                      </div>
                      <div className="mt-2">
                        <CpcbDshbrdClr
                          ClassNme="dshbrditm dshcyt"
                          TotalCount={state.cytprcdcnt + ' No'}
                          TotalWt={state.cytprcdwt + ' Kg'}
                        ></CpcbDshbrdClr>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
              {/* Start Row */}

              <div className="row" style={{marginTop:'-40px'}}>
                <div className="col-md-3 col-sm-1 stretch-card grid-margin">
                  <div className="card bg-primary card-img-holder text-black">
                    <div className="card-body">
                      {/* <img src={circle} className="card-img-absolute" alt="" /> */}
                      <div>
                     
                      </div>
                      <h4 className="font-weight-bold mb-3 text-center">HCF VISITED</h4>
                      <h3 className="mb-2 text-center">{state.hcfvst}</h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-sm-1 stretch-card grid-margin">
                  <div className="card bg-dark card-img-holder text-white">
                    <div className="card-body p-4">
                      {/* <img src={circle} className="card-img-absolute" alt="" /> */}
                      <h4 className="font-weight-bold mb-3">HCF NOT VISITED</h4>
                      <h3 className="mb-2">{state.hcfpnd}3459</h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-sm-1 stretch-card grid-margin">
                  <div className="card bg-warning card-img-holder text-black">
                    <div className="card-body p-4">
                      {/* <img src={circle} className="card-img-absolute" alt="" /> */}
                      <h4 className="font-weight-bold mb-3 text-center">Incomplete Data Received</h4>
                      <h3 className="mb-2 text-center">{state.errapi}</h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-sm-1 stretch-card grid-margin">
                  <div className="card bg-success card-img-holder text-white">
                    <div className="card-body p-4">
                      {/* <img src={circle} className="card-img-absolute" alt="" /> */}
                      <h4 className="font-weight-bold mb-3">ACTIVE VEHICLES</h4>
                      <h3 className="mb-2">{state.vchact}54</h3>
                    </div>
                  </div>
                </div>
              </div>

              {/* End Row */}
            </div>
            {/* <div className="row">
                <div className="col-md-1 d-none d-sm-block  stretch-card grid-margin"></div>
                <div className="col-md-10 col-sm-10 stretch-card grid-margin">
                  <div className="card bg-gradient-dark card-img-holder text-black">
                    <div className="card-body ">
                      <Chart 
                      chartType="Bar"
                      width={"100%"}
                      height={'600px'}
                      data= {datachrt}
                      options={options}
                      />

                      
                    </div>
                  </div>
                </div>
                </div>     */}
             
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};



export default Dashboard;
