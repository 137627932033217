
import React, { Children }   from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { getCmpId } from "./utilities"

type Props = {
    children :JSX.Element
}
 export const NrjRequire  = ({children }:Props) : JSX.Element =>{
    
    let cmpid = getCmpId();
    if (cmpid){
        let cmp = Number(cmpid)
        if (cmp > 100){ 
            return children;
        }
    }
    return <Navigate to ="/"></Navigate>
}

// export default NrjRequire({children})