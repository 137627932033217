import React, { Component, lazy, useContext, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
// import { Route, Router, useLocationProperty,navigate  } from 'wouter';
import { Loader } from "rsuite";
import logo from "./logo.svg";
import "./App.css";
import "./styles/Nrjwtr.css";
import { NrjRequire } from "./utilities/NrjRequire";
import DsgnPg from "./app/brds/DsgnPg";
import CpcbHo from "./app/brds/CpcbHo";
import DshBrdCbtwReg from "./app/dshbrd/DshBrdCbtwReg";
import DashBoardRgHcf from "./app/dshbrd/DashBoardRgHcf";
import NewReg from "./app/admn/cbwtf/NewReg";
import { GlobalContextProvider } from "./components/reusable/context";
import DashBoardSec from "./app/dshbrd/DashBoardSec";
import DashboardIdeal from "./app/dshbrd/DashboardIdeal";
import DashboardReal from "./app/dshbrd/DashBaordReal";
import Dashboard from "./app/dshbrd/Dashboard";
import CpchHoPg from "./app/brds/CpchHoPg";
import RgnDirctrLst from "./app/brds/RgnDirctrLst";
import SttDirctrLst from "./app/brds/SttDirctrLst";
import NrjLogin from "./login/NrjLogin";
import DashBoardClrGrph from "./app/dshbrd/DashBoardClrGrph";
import NrjAgBtn from "./components/reusable/NrjAgBtn";
import { setCmpId, setUsrId } from "./utilities/utilities";
const LzCbwtfSrch = React.lazy(() => import("./app/brds/CbwtfSrch"));
const LzDashBoardCBWTLst = React.lazy(
  () => import("./app/dshbrd/DashBoardCBWTLst")
);
const LzDashBoardClrRw = React.lazy(
  () => import("./app/dshbrd/DashBoardClrRw")
);
const LzNrjLgn = React.lazy(() => import("./login/NrjLogin"));
const LzChangePassword = React.lazy(()=>import('./app/login/ChangePasswod'))
const LzWtrCentral = React.lazy(() => import("./app/brds/WtrCentral"));
const LzLstReg = React.lazy(() => import("./app/hcf/LstReg"));
const LzWstCntIncnrt = React.lazy(() => import("./app/hcf/WstCndIncnrt"));
const LzDshbrdslct = React.lazy(() => import("./app/dshbrd/Dshbrdslct"));
const LzMstr = React.lazy(() => import("./app/brds/Mstrs"));
const LzDshCup = React.lazy(() => import("./app/dshbrd/dshbrd/DshBrd"));
const LzCndInciBrcdVndr = React.lazy(() => import("./app/hcf/CndInciBrcdVndr"));
const LzCndIncinSVndr = React.lazy(() => import("./app/hcf/CndInciSftVndr"));
const LzCndIncinSftFrt = React.lazy(() => import("./app/hcf/CndincinSftFrt"));
const LzLgEml = React.lazy(() => import("./app/login/LoginOTP"));
const LzDshBrd = React.lazy(() => import("./app/dshbrd/Dashbrd"));
const LzRgDrct = React.lazy(() => import("./app/brds/RgnDirctr"));
const LzRgnDirtLst = React.lazy(() => import("./app/brds/RgnDirctrLst"));
const LzSttDircctrLst = React.lazy(() => import("./app/brds/SttDirctrLst"));
const LzStateBrd = React.lazy(() => import("./app/brds/StateBrd"));
const LzPncStts = React.lazy(() => import("./app/admn/bckofc/PncStts"));
const LzRgnOffcLst = React.lazy(() => import("./app/brds/RgnOffcLst"));
const LzReginalOffce = React.lazy(() => import("./app/brds/ReginalOffce"));
const LzPccList = React.lazy(() => import("./app/brds/PccBrdLst"));
const LzPccBrd = React.lazy(() => import("./app/brds/PccBrd"));
const LzLoginUsr = React.lazy(() => import("./app/login/LoginUsrPsw"));
const LzCpcbHo = React.lazy(() => import("./app/brds/CpchHoPg"));
const LzCbwtfLst = React.lazy(() => import("./app/admn/cbwtf/CbwtfLst"));
const LzCbwtfMn = React.lazy(() => import("./app/hcf/CbwtfMn"));
const LzBarVndr = React.lazy(() => import("./app/admn/cbwtf/BarVndr"));
const LzDashBoard = React.lazy(() => import("./app/dshbrd/Dashboard"));
const LzSftwftr = React.lazy(() => import("./app/admn/cbwtf/Sftwftr"));
const LzSftwdts = React.lazy(() => import("./app/admn/cbwtf/SftwrDts"));
const LzSttsAll = React.lazy(() => import("./app/brds/SttsAll"));
const LzSttPnd = React.lazy(() => import("./app/brds/PndPsw"));
const LzAnnlRpt = React.lazy(() => import("./app/AnnualRpt/AnnlRpt"));
const LzAnnlMisc = React.lazy(() => import("./app/AnnualRpt/AnnlMisc"));
const LzAnnlEqp = React.lazy(() => import("./app/AnnualRpt/AnnlEqp"));
const LzAnnlWstStrg = React.lazy(() => import("./app/AnnualRpt/AnnlWstStrg"));
const LzAnnlWstWt = React.lazy(() => import("./app/AnnualRpt/AnnlWstWt"));
const LzMisRpt = React.lazy(() => import("./app/Reports/MisReports"));
const LzHcfNmbrVisited = React.lazy(() => import("./app/Reports/MisHcfNmbrVisited"));
const LzHcfNonVisited = React.lazy(() => import("./app/Reports/MisHcfNonVisited"));




const LzDashBoardRgHCF = React.lazy(
  () => import("./app/dshbrd/DashBoardRgHcf")
);
const LzNewReg = React.lazy(() => import("./app/admn/cbwtf/NewReg"));
const LzDashBrdBagWise = React.lazy(
  () => import("./app/dshbrd/DashBrdBagWise")
);
const LzDashGraph = React.lazy(() => import("./app/dshbrd/DashBoardClrGrph"));
const LzHCFDisplay = React.lazy(() => import("./app/Reports/HcfDisplay"));
const LzWstBgs = React.lazy(() => import("./app/Reports/WstbgDisplay"));
const LzListCbwtfWstData = React.lazy(() => import("./app/Reports/ListCbwtfWstData"));
const LzHCFCnt = React.lazy(() => import("./app/Reports/HcfCount"));
const LzGridDisply = React.lazy(() => import("./app/Reports/GridDisplay"))
const LzGridDisplyCbwtf = React.lazy(() => import("./app/Reports/GridDisplayCbwtF"))
const LzCbwtfLstRep = React.lazy(() => import("./app/Reports/CbwtfLst"))
const LzCbwtfregSmr = React.lazy(() => import("./app/Reports/CbwtfregSmr"))
const LzDshCbwtfReg = React.lazy(() => import("./app/Reports/DshBrdCbwtfReg"))
const LzAnnualRepA = React.lazy(() => import("./app/Reports/Annual/AnnualA"))
const LzAnnualRepB = React.lazy(() => import("./app/Reports/Annual/AnnualB"))
const LzAnlauthB = React.lazy(() => import("./app/Reports/Annual/AnlauthB"))
const LzAnlCbwtC = React.lazy(() => import("./app/Reports/Annual/anlCbwtC"))
const LzAnlCbwtD = React.lazy(() => import("./app/Reports/Annual/anlCbwtD"))
const LzAnlCbwtE = React.lazy(() => import("./app/Reports/Annual/anlCbwtE"))
const LzAnlHcfA = React.lazy(() => import("./app/Reports/Annual/anlHcfA"))
const LzBigBag = React.lazy(() => import("./app/Reports/BigBag"))


const LzTrackingBmw = React.lazy(() => import("./app/Reports/TrakingBmw"));
const LzWrongWstBgCbwtf = React.lazy(() => import("./app/Reports/WrongWstBgCbwtf"));
const LzListHCF = React.lazy(() => import("./app/Reports/ListHCF"));
const LzClrCbwtfWst = React.lazy(() => import("./app/Reports/ClrCbwtfWst"))
const LzStt_Annlauth = React.lazy(()=>import("./app/AnnualRpt/State/Stt_Annlauth"))
const LzStt_Annldstr = React.lazy(()=>import('./app/AnnualRpt/State/Stt_Annldstr'));
const LzStt_Annlcptv = React.lazy(()=>import('./app/AnnualRpt/State/Stt_Annlcptv'));
const LzAnnCbwtf = React.lazy(()=> import("./app/AnnualRpt/State/Stt_Annlcbwtf"))
const LzAnnlFrst= React.lazy(()=> import("./app/AnnualRpt/State/Stt_Annlfrst"))
const LzNrjForgotPassword = React.lazy(()=>import("./login/NrjForgotPassword"))
const LzMisHCFNotVstd = React.lazy(()=>import("./app/Reports/MisHCFNotVstd"))
const LzMisBagcount = React.lazy(()=>import("./app/Reports/MisBagCountLbl"))
// const LzMisBagcntwithoutLbl = React.lazy(()=>import("./app/Reports/MisBagCntwthoutLbl"))
// const LzMisBagcntwithoutGeo = React.lazy(()=>import("./app/Reports/MisBagCntwthoutGeo"))
const LzMisBagcntwthGeo = React.lazy(()=>import("./app/Reports/MisBagCntwthGeo"))
const LzBagCntPrHr = React.lazy(()=>import("./app/dshbrd/BagCntPrHr"))
const LzBagCntGrid = React.lazy(()=>import("./app/dshbrd/bagCnthrGrid"))
const LzHcfCtgCnt = React.lazy(()=>import("./app/dshbrd/HcfCtgCnt"))
const LzDrillRgd = React.lazy(()=>import('./app/Reports/DrillRgd'));
const LzDrillState = React.lazy(()=>import('./app/Reports/DrillState'));
const LzDrillCbwtf = React.lazy(()=>import('./app/Reports/DrillCbwtf'));
const LzHcfCtgCntGrid = React.lazy(()=>import('./app/dshbrd/HcfCtgCntGrid'));

function App() {
  document.title = "CPCB";
  setCmpId( "104560");
  setUsrId( "104560");

  return (
    <div>
      <BrowserRouter>
        <GlobalContextProvider>
          <Routes>
            <Route
              path="/"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  {/* <LzAnnlFrst></LzAnnlFrst> */}
                  <LzNrjLgn />
                  {/* <LzCbwtfLst></LzCbwtfLst> */}
                  {/* <LzCbwtfMn></LzCbwtfMn> */}
                  {/* <RgnDirctrLst></RgnDirctrLst> */}
                  {/* <LzStateBrd></LzStateBrd> */}
                  {/* <SttDirctrLst></SttDirctrLst> */}
                  {/* <LzRgDrct></LzRgDrct> */}
                  {/* <LzCbwtfMn></LzCbwtfMn> */}
                  {/* <LzBarVndr /> */}
                  {/* <LzDashBoard></LzDashBoard> */}
                  {/* <DashBoardSec></DashBoardSec> */}
                  {/* <LzDashBoardClrRw></LzDashBoardClrRw> */}
                  {/* <LzDashBrdBagWise></LzDashBrdBagWise> */}
                  {/* <LzDashBoardCBWTLst></LzDashBoardCBWTLst> */}
                  {/* <DashboardIdeal></DashboardIdeal> */}
                  {/* <DashboardReal></DashboardReal> */}
                  {/* <DashBoardRgHcf></DashBoardRgHcf> */}
                  {/* <LzCbwtfMn /> */}
                  {/* <NewReg /> */}
                  {/* <LzLoginUsr /> */}
                  {/* <CpchHoPg></CpchHoPg> */}
                  {/* <NrjLogin></NrjLogin> */}
                  {/* <LzLgEml /> */}
                  {/* <DashBoardClrGrph></DashBoardClrGrph> */}
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/ideal"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  {/* <LzWtrCentral /> */}
                  <DashboardIdeal></DashboardIdeal>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/cbwtsmr"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzDshCbwtfReg></LzDshCbwtfReg>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/dshreg"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzCbwtfregSmr></LzCbwtfregSmr>
                </React.Suspense>
              }
            ></Route>

            <Route
              path="/cbwtfdspl"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzCbwtfLstRep></LzCbwtfLstRep>
                  {/* <LzGridDisplyCbwtf path={"cbwtfregtdy"}
                  inputData={{lvl:'CPCB', who:'', dtno:'0'}}
                  cols={[
                    { field: "cbwtfid", hidden: true, width: 50, headerName: "S No" },
                    {
                      field: "cbwtfnm",
                      width: 290,
                      headerName: "CBWTF",
                    },
                    // {
                    //   field:"dts",
                    //   headerName: "Modify",
                    //   width: 100,
                    //   hidden: false,
              
                    //   cellRenderer: NrjAgBtn,
                    //   cellRendererParams: {
                    //     showApi: "",
                    //     buttonText: "Modify",
                    //     fldName: "dts",
                    //     colId: "show",
                    //   }
                    // },
                    {
                      field: "cty",
                      width: 100,
                      headerName: "City",
                    },
                    {
                      field: "contprnm",
                      width: 150,
                      headerName: "Contact Person",
                    },
                    {
                      field: "mob",
                      width: 120,
                      headerName: "Mobile",
                    },
                    {
                      field: "eml",
                      width: 100,
                      headerName: "E Mail",
                    },
                    {
                      field: "state",
                      width: 100,
                      headerName: "State",
                    },
                    {
                      field: "addra",
                      width: 150,
                      headerName: "Address I",
                    },
                    {
                      field: "addrb",
                      width: 150,
                      headerName: "Address II",
                    },
                    {
                      field: "addrc",
                      width: 150,
                      headerName: "Address III",
                    }
                    
                  ]}></LzGridDisplyCbwtf> */}
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/hcflist"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzListHCF path={"hcflist"}
                    inputData={{ lvl: 'CPCB', who: '', dtno: '0' }}
                    cols={[
                      { field: "cbwtfid", hidden: true, width: 50, headerName: "S No" },
                      {
                        field: "hcfnm",
                        width: 320,
                        headerName: "HCF",
                      },
                      {
                        field: "hcfcod",
                        width: 100,
                        headerName: "SPCB Code",
                      },
                      {
                        field: "cty",
                        width: 100,
                        headerName: "City",
                      },
                      {
                        field: "cntprsn",
                        width: 150,
                        headerName: "Contact Person",
                      },
                      {
                        field: "phn",
                        width: 120,
                        headerName: "Mobile",
                      },
                      {
                        field: "hcftyp",
                        width: 70,
                        headerName: "HCF Type",
                      },
                      {
                        field: "ltt",
                        width: 100,
                        headerName: "Latitude",
                      },
                      {
                        field: "lgt",
                        width: 100,
                        headerName: "Longtitude",
                      },
                      {
                        field: "addra",
                        width: 150,
                        headerName: "Address I",
                      },
                      {
                        field: "addrb",
                        width: 150,
                        headerName: "Address II",
                      },
                      {
                        field: "addrc",
                        width: 150,
                        headerName: "Address III",
                      },
                      {
                        field: 'stt',
                        width: 100,
                        headerName: 'State'
                      },
                      {
                        field:'eml',
                        width: 200,
                        headerName: 'Email'
                      },
                      {
                        field: 'nobd',
                        width: 100,
                        headerName: 'No of beds'
                      },
                      {
                        field: 'bluscl',
                        width:100,
                        headerName: 'Blutooth Scale'
                      },
                      {
                        field: 'andrapp',
                        width:100,
                        headerName:'Android App'

                      },
                      {
                        field: 'rgd',
                        width: 120,
                        headerName: 'Regional Directory'
                      },
                      

                    ]}></LzListHCF>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/hcfbd"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzListHCF path={"hcfbd"}
                    inputData={{ lvl: 'CPCB', who: '', dtno: '0' }}
                    cols={[
                      { field: "cbwtfid", hidden: true, width: 50, headerName: "S No" },
                      {
                        field: "hcfnm",
                        width: 320,
                        headerName: "HCF",
                      },
                      {
                        field: "hcfcod",
                        width: 100,
                        headerName: "SPCB Code",
                      },
                      {
                        field: "cty",
                        width: 100,
                        headerName: "City",
                      },
                      {
                        field: "cntprsn",
                        width: 180,
                        headerName: "Contact Person",
                      },
                      {
                        field: "phn",
                        width: 120,
                        headerName: "Mobile",
                      },
                      {
                        field: "nobd",
                        width: 120,
                        headerName: "No of Beds",
                      },
                      {
                        field: "ltt",
                        width: 100,
                        headerName: "Latitude",
                      },
                      {
                        field: "lgt",
                        width: 100,
                        headerName: "Longtitude",
                      },
                      {
                        field: "addra",
                        width: 150,
                        headerName: "Address I",
                      },
                      {
                        field: "addrb",
                        width: 150,
                        headerName: "Address II",
                      },
                      {
                        field: "addrc",
                        width: 150,
                        headerName: "Address III",
                      }

                    ]}></LzListHCF>
                </React.Suspense>
              }
            ></Route>
            <Route
            path="/spcb_cbwtf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                <LzAnnCbwtf></LzAnnCbwtf>
                </NrjRequire>
              </React.Suspense>
            }
        ></Route>
         <Route
            path="/spcb_frst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                <LzAnnlFrst></LzAnnlFrst>
                </NrjRequire>
              </React.Suspense>
            }
        ></Route>
        <Route
            path="/spcb_auth"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzStt_Annlauth />
                </NrjRequire>
              </React.Suspense>
            }
        ></Route>
        <Route
            path="/spcb_dstr"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzStt_Annldstr />
                </NrjRequire>
              </React.Suspense>
            }
        ></Route>
        <Route
            path="/spcb_hcfcptv"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzStt_Annlcptv />
                </NrjRequire>
              </React.Suspense>
            }
        ></Route>
            <Route
              path="/hcfnbd"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzListHCF path={"hcfnbd"}
                    inputData={{ lvl: 'CPCB', who: '', dtno: '0' }}
                    cols={[
                      { field: "cbwtfid", hidden: true, width: 50, headerName: "S No" },
                      {
                        field: "hcfnm",
                        width: 320,
                        headerName: "HCF",
                      },
                      {
                        field: "hcfcod",
                        width: 100,
                        headerName: "SPCB Code",
                      },
                      {
                        field: "cty",
                        width: 100,
                        headerName: "City",
                      },
                      {
                        field: "cntprsn",
                        width: 150,
                        headerName: "Contact Person",
                      },
                      {
                        field: "phn",
                        width: 120,
                        headerName: "Mobile",
                      },
                      {
                        field: "hcftyp",
                        width: 70,
                        headerName: "HCF Type",
                      },
                      {
                        field: "ltt",
                        width: 100,
                        headerName: "Latitude",
                      },
                      {
                        field: "lgt",
                        width: 100,
                        headerName: "Longtitude",
                      },
                      {
                        field: "addra",
                        width: 150,
                        headerName: "Address I",
                      },
                      {
                        field: "addrb",
                        width: 150,
                        headerName: "Address II",
                      },
                      {
                        field: "addrc",
                        width: 150,
                        headerName: "Address III",
                      }

                    ]}></LzListHCF>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/hcflstgrd"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzGridDisply path={"hcfregtdy"}
                    inputData={{ lvl: 'CPCB', who: '', dtno: '0' }}
                    cols={[
                      { field: "cbwtfid", hidden: true, width: 50, headerName: "S No" },
                      {
                        field: "hcfnm",
                        width: 320,
                        headerName: "HCF",
                      },
                      {
                        field: "hcfcod",
                        width: 100,
                        headerName: "SPCB Code",
                      },
                      {
                        field: "cty",
                        width: 100,
                        headerName: "City",
                      },
                      {
                        field: "cntprsn",
                        width: 150,
                        headerName: "Contact Person",
                      },
                      {
                        field: "phn",
                        width: 120,
                        headerName: "Mobile",
                      },
                      {
                        field: "hcftyp",
                        width: 70,
                        headerName: "HCF Type",
                      },
                      {
                        field: "ltt",
                        width: 100,
                        headerName: "Latitude",
                      },
                      {
                        field: "lgt",
                        width: 100,
                        headerName: "Longtitude",
                      },
                      {
                        field: "addra",
                        width: 150,
                        headerName: "Address I",
                      },
                      {
                        field: "addrb",
                        width: 150,
                        headerName: "Address II",
                      },
                      {
                        field: "addrc",
                        width: 150,
                        headerName: "Address III",
                      }

                    ]}></LzGridDisply>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/dshnw"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzDashBoardClrRw />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/dshnwgrph"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzDashGraph />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/hcfcbwtf"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzHCFCnt
                    groupBy={"cbwtfid"}
                    mypage={1}
                    cols={[
                      { field: "srno", hidden: false, width: 40, headerName: "SNo" },
                      { field: "cbwtfid", hidden: true, width: 100, headerName: "" },
                      {
                        field: "cbwtfnm",
                        width: 400,
                        headerName: "CBWTF",
                      },
                      {
                        field: "hcfcount",
                        width: 250,
                        headerName: "Health Care Facility",
                      },
                      {
                        field: "beds",
                        width: 100,
                        headerName: "Total Beds",
                      },
                      {
                        field: "bedded",
                        width: 100,
                        headerName: "Bedded HCF",
                      },
                      {
                        field: "nonbedded",
                        width: 100,
                        headerName: "Non Bedded HCF",
                      },
                      {
                        field: "stt",
                        width: 100,
                        headerName: "State",
                      },
                      {
                        field: "rgd",
                        width: 150,
                        headerName: "RD",
                      },
                    ]}
                  ></LzHCFCnt>
                </React.Suspense>
              }
            ></Route>

            <Route
              path="/hcfrgd"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzHCFCnt
                    groupBy={"rgd"}
                    mypage={2}
                    cols={[
                      { field: "idsr", hidden: true, width: 100, headerName: "S No" },
                      {
                        field: "_id",
                        width: 400,
                        headerName: "RD",
                      },
                      {
                        field: "bedded",
                        width: 220,
                        headerName: "Bedded HCF ",
                      },
                      {
                        field: "nonbedded",
                        width: 100,
                        headerName: "Non Bedded HCF",
                      },
                      {
                        field: "hcfcount",
                        width: 250,
                        headerName: "Health Care Facility",
                      },
                      {
                        field: "beds",
                        width: 220,
                        headerName: "Total Beds",
                      }
                    ]}
                  ></LzHCFCnt>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/hcfstt"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzHCFCnt
                    groupBy={"stt"}
                    mypage={3}
                    cols={[
                      { field: "idsr", hidden: true, width: 100, headerName: "S No" },
                      {
                        field: "_id",
                        width: 400,
                        headerName: "SCPB",
                      }, {
                        field: "bedded",
                        width: 200,
                        headerName: "Bedded HCF ",
                      },
                      {
                        field: "nonbedded",
                        width: 190,
                        headerName: "Non Bedded HCF",
                      },
                      {
                        field: "hcfcount",
                        width: 200,
                        headerName: "Health Care Facility",
                      },
                      {
                        field: "beds",
                        width: 200,
                        headerName: "Total Beds",
                      },

                    ]}
                  ></LzHCFCnt>
                </React.Suspense>
              }
            ></Route>


            <Route
              path="/trckbmw"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzTrackingBmw />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/ListWrngHCFCode"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzWrongWstBgCbwtf />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/lstwst"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzDashBoardCBWTLst />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/bgws"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzDashBrdBagWise></LzDashBrdBagWise>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/real"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  {/* <LzWtrCentral /> */}
                  <Dashboard></Dashboard>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/hcfdspl"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzHCFDisplay></LzHCFDisplay>
                </React.Suspense>
              }
            ></Route>

            <Route
              path="/wstbgs"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzListCbwtfWstData />

                  {/* <LzWstBgs></LzWstBgs> //card display of waste bags */}
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/cntr"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    {/* <LzWtrCentral /> */}
                    <LzCpcbHo />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="forgotPassword"
              element={
                <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                  <LzNrjForgotPassword />
                </React.Suspense>
              }
          ></Route>
            <Route
              path="/changePwd"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzChangePassword />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/dshmn"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzDashBoard />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/dshcbwtf"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzDashBoardRgHCF />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/hcflst"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <NrjRequire>
                      <LzLstReg />
                    </NrjRequire>
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/regdts"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzWstCntIncnrt />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/brcdv"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzCndInciBrcdVndr />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/sftwv"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzCndIncinSVndr />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/sftr"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzCndIncinSftFrt />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/dshbrd"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzDshbrdslct />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/dsgn"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzMstr Label="Designation" Db="shrtrm" Typr="841" />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/mcty"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzMstr Label="City" Db="shrtrm" Typr="398" />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/dshb"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  {/* <LzDshCup></LzDshCup> */}
                  <LzDshBrd></LzDshBrd>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/rgnd"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzRgDrct />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/rgndlst"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzRgnDirtLst />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/sttdlst"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzSttDircctrLst />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/stt"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzStateBrd />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/pnclst"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzPncStts />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/roflst"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzRgnOffcLst />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/pcclst"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzPccList />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/pcc"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzPccBrd />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/rgnofc"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzReginalOffce />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/cbwtf"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzCbwtfMn />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/brvndr"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzBarVndr />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/sftwft"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzSftwftr />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/sftwdts"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzSftwdts />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>

            <Route
              path="/clrCbwtfWst"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzClrCbwtfWst />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/sttall"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzSttsAll />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/sttpnd"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzSttPnd />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            //
            <Route
              path="/nwcbwtf"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNewReg />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/CbwtfSrch"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzCbwtfSrch ctg={"cbwtf"} ></LzCbwtfSrch>
                  </NrjRequire>

                </React.Suspense>
              }
            ></Route>
            <Route
              path="/HcfSrch"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzCbwtfSrch ctg={"hcf"} ></LzCbwtfSrch>
                  </NrjRequire>

                </React.Suspense>
              }
            ></Route>
            <Route
              path="/annualRepA"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzAnnualRepA></LzAnnualRepA>
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
             <Route
              path="/annualRepB"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzAnnualRepB></LzAnnualRepB>
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/anlauthB"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzAnlauthB></LzAnlauthB>
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/anlCbwtC"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzAnlCbwtC></LzAnlCbwtC>
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/anlCbwtD"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzAnlCbwtD></LzAnlCbwtD>
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/anlCbwtE"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzAnlCbwtE></LzAnlCbwtE>
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/anlHcfA"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzAnlHcfA></LzAnlHcfA>
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/annlRpt"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzAnnlRpt />
                </React.Suspense>
              }
            ></Route>

            <Route
              path="/annlMisc"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzAnnlMisc />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/annlEqp"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzAnnlEqp />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/annlWstStrg"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzAnnlWstStrg />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/annlWstWt"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzAnnlWstWt />
                </React.Suspense>
              }
            ></Route>
              <Route
              path="/misRpt"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzMisRpt />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/hcfNmbrVisited"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzHcfNmbrVisited />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/hcfnotvst"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzMisHCFNotVstd></LzMisHCFNotVstd>
                </React.Suspense>
              }
            ></Route>
              <Route
              path="/hcfNonVisited"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzHcfNonVisited></LzHcfNonVisited>
                </React.Suspense>
              }
            ></Route>
               <Route
              path="/bigBag"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzBigBag></LzBigBag>
                </React.Suspense>
              }
            ></Route>
              <Route
              path="/bagcntwthLbl"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzMisBagcount></LzMisBagcount>
                </React.Suspense>
              }
            ></Route>
             <Route
              path="/drillRgd"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzDrillRgd></LzDrillRgd>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/drillState"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzDrillState></LzDrillState>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/drillCbwtf"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzDrillCbwtf></LzDrillCbwtf>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/bagcntwthGeo"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzMisBagcntwthGeo></LzMisBagcntwthGeo>
                </React.Suspense>
              }
            ></Route>
              <Route
              path="/bagCntPrHr"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzBagCntPrHr></LzBagCntPrHr>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/bagCntGrid"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzBagCntGrid></LzBagCntGrid>
                </React.Suspense>
              }
            ></Route>
             <Route
              path="/hcfCtgCnt"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzHcfCtgCnt></LzHcfCtgCnt>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/hcfCtgCntGrid"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzHcfCtgCntGrid></LzHcfCtgCntGrid>
                </React.Suspense>
              }
            ></Route>
            
          </Routes>
        </GlobalContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default React.memo(App);
